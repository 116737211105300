<!--
 * @Description: E6andG7调度任务
 * @Author: ChenXueLin
 * @Date: 2021-10-14 08:59:17
 * @LastEditTime: 2023-03-23 09:38:59
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title
        @resize="handleTableTitleResize"
        :canSetting="true"
        :tableId="tableId"
        @updateData="queryList"
      >
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="workOrderIds">
              <!-- <el-input
                v-model="searchForm.workOrderId"
                placeholder="工单ID"
                title="工单ID"
                maxlength="10"
                @input="
                  searchForm.workOrderId = searchForm.workOrderId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input> -->
              <input-plus
                v-model="searchForm.workOrderIds"
                valueType="Array"
                placeholder="工单ID"
                title="工单ID"
                label="工单ID"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="workOrderStatusList">
              <e6-vr-select
                v-model="searchForm.workOrderStatusList"
                :data="workStatusList"
                placeholder="工单状态"
                title="工单状态"
                clearable
                multiple
                :loading="selectLoading"
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="inspectionSettleStatus">
              <e6-vr-select
                v-model="searchForm.inspectionSettleStatus"
                :data="inspectionSettleStatusList"
                placeholder="质检结算状态"
                title="质检结算状态"
                clearable
                multiple
                :loading="selectLoading"
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSecondClassList">
              <e6-vr-select
                v-model="searchForm.taskSecondClassList"
                :data="taskTypeList"
                placeholder="工单类型"
                title="工单类型"
                :loading="selectLoading"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="workTypeTagList">
              <e6-vr-select
                v-model="searchForm.workTypeTagList"
                :data="WorkTypeTagList"
                placeholder="工单类型标签"
                title="工单类型标签"
                clearable
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSourceList">
              <e6-vr-select
                v-model="searchForm.taskSourceList"
                :data="taskSourceList"
                placeholder="工单来源"
                title="工单来源"
                :loading="selectLoading"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneType">
              <e6-vr-select
                v-model="searchForm.sceneType"
                :data="sceneTypeList"
                :loading="selectLoading"
                placeholder="场景类型"
                title="场景类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskNumber">
              <el-input
                v-model="searchForm.taskNumber"
                placeholder="任务单号"
                title="任务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="scheduleTaskId">
              <el-input
                v-model="searchForm.scheduleTaskId"
                placeholder="调度任务ID"
                title="调度任务ID"
                @input="
                  searchForm.scheduleTaskId = searchForm.scheduleTaskId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>
            <el-form-item prop="dispatchEngineer" class="search-item--1">
              <el-input
                v-model="searchForm.dispatchEngineer"
                placeholder="工程师"
                title="工程师"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpAttrId">
              <e6-vr-select
                v-model="searchForm.corpAttrId"
                :data="corpAtrrList"
                placeholder="客户属性"
                :loading="selectLoading"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc',
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="companyNos">
              <e6-vr-select
                v-model="searchForm.companyNos"
                :data="serviceCompanyList"
                placeholder="签单组织"
                title="签单组织"
                clearable
                multiple
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="onlinePlatforms">
              <e6-vr-select
                v-model="searchForm.onlinePlatforms"
                :data="onlinePlatList"
                placeholder="上线平台"
                title="上线平台"
                clearable
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="regionalIdList">
              <e6-vr-select
                v-model="searchForm.regionalIdList"
                :data="areaList"
                :loading="selectLoading"
                placeholder="所属大区"
                title="所属大区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="areaIdList">
              <e6-vr-select
                v-model="searchForm.areaIdList"
                :data="cityList"
                :loading="selectLoading"
                placeholder="所属片区"
                title="所属片区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="provinceCode">
              <e6-vr-select
                v-model="searchForm.provinceCode"
                :data="provinceList"
                placeholder="省"
                title="省"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'code',
                  label: 'name',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="cityCode">
              <e6-vr-select
                v-model="searchForm.cityCode"
                :data="cityNameList"
                placeholder="市"
                title="市"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'code',
                  label: 'name',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="countyCode">
              <e6-vr-select
                v-model="searchForm.countyCode"
                :data="districtList"
                placeholder="区"
                title="区"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'code',
                  label: 'name',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="deliveryWays">
              <e6-vr-select
                v-model="searchForm.deliveryWays"
                :data="isDeliverInstallList"
                placeholder="送装方式"
                title="送装方式"
                clearable
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="warnType">
              <e6-vr-select
                v-model="searchForm.warnType"
                :data="warnTypeList"
                placeholder="项目标签"
                title="项目标签"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="warnStatus">
              <e6-vr-select
                v-model="searchForm.warnStatus"
                :data="warnStatusList"
                placeholder="预警超期"
                title="预警超期"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="firstOverdueReason">
              <e6-vr-select
                v-model="searchForm.firstOverdueReason"
                :data="firstReasonList"
                placeholder="一级超期原因"
                title="一级超期原因"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
                @change="changeReason"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondOverdueReason">
              <e6-vr-select
                v-model="searchForm.secondOverdueReason"
                :data="secondReasonList"
                placeholder="二级超期原因"
                title="二级超期原因"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="hasWarnReason">
              <e6-vr-select
                v-model="searchForm.hasWarnReason"
                :data="hasWarnReasonList"
                placeholder="是否维护超期原因"
                title="是否维护超期原因"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="labelTypeList">
              <e6-vr-select
                v-model="searchForm.labelTypeList"
                :data="labelTypeList"
                placeholder="标签"
                title="标签"
                clearable
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="工单完结时间"
                ref="effectiveDatePicker"
                v-model="searchForm.finishTime"
                title="工单完结时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="工单完结时间（始）"
                end-placeholder="工单完结时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="deadLineTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="关单核算时间"
                ref="effectiveDatePicker"
                v-model="searchForm.deadLineTime"
                title="关单核算时间"
                :picker-options="pickerOptions('searchForm.deadLineTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="关单核算时间（始）"
                end-placeholder="关单核算时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <el-button
            type="primary"
            @click="handleReason"
            style="margin-right:10px;"
            v-has="103"
          >
            超期原因
          </el-button>
          <el-button
            type="primary"
            @click="handleExportDialog(1)"
            style="margin-right:10px;"
          >
            按设备导出
          </el-button>
          <i
            class="e6-icon-export_fill"
            title="导出"
            @click="handleExportDialog(2)"
          ></i>
          <el-button
            type="primary"
            @click="toRecord"
            style="margin-right:10px;"
          >
            历史记录
          </el-button>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelect"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            :render-header="renderHeader"
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div class="table-label" v-if="column.fieldName === 'tagValue'">
                <span
                  v-for="(item, index) in row.tagValue"
                  :key="index"
                  class="label-box"
                  >{{ item }}</span
                >
              </div>
              <span
                v-else-if="column.fieldName === 'status'"
                :class="[
                  'status',
                  row.status === 1
                    ? 'finish'
                    : row.status === 2
                    ? 'ongoing'
                    : '',
                ]"
              >
                已完成
              </span>

              <span v-else-if="column.fieldName === 'workNo'">
                <el-button type="text" @click="handleGoToDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>

              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="70">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 导出弹框 -->
    <export-dialog
      :exportVisible.sync="exportVisible"
      @confirm="handleExport"
      @refreshData="queryList"
      ref="exportDialog"
      :tableId="tableId"
    ></export-dialog>
    <!-- <change-engineer
      :isChange="isChange"
      :changeType="changeType"
      @handleClose="handleClose"
      @refresh="getStatus"
      :basicInfo="basicInfo"
      :taskId="taskId"
      :customerId="customerId"
      :itemIdList="itemIdList"
      :itemList="itemList"
    ></change-engineer> -->
    <overdue-reason-dialog
      :itemIdList="selectColumns.map((item) => item.workOrderId)"
      :overdueReasonVisible="overdueReasonVisible"
      @refresh="queryData"
      @close="closeDialog"
    ></overdue-reason-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import exportData from "@/mixins/exportData";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import exportDialog from "@/components/exportDialog/index";
import inputPlus from "@/components/inputPlus";
// import ChangeEngineer from "../../../WOS/workOrderManage/taskListManage/detail/components/changeEngineer.vue";
import {
  getWorkCorpList,
  getProviceList,
  getColumn,
  findDownList,
  findCorpAttr,
  getG7workOrderList,
  exportG7workOrderList,
  exportWorkItemDetailList,
  getListAreaAll,
} from "@/api";
import OverdueReasonDialog from "@/components/overdueReasonDialog/overdueReasonDialog.vue";
const nowTime = new Date().getTime(); // 打开页面时的时间戳
export default {
  name: "WorkOrderInfo",
  data() {
    return {
      tableId: 8008,
      loading: false,
      provinceList: [],
      cityNameList: [],
      districtList: [],
      searchForm: {
        provinceCode: "",
        cityCode: "",
        countyCode: "",
        workOrderIds: [], //工单ID
        workOrderStatusList: [], //工单状态
        taskSecondClassList: [], //工单类型
        taskSourceList: [], //工单来源
        sceneName: "", //场景名称
        onlinePlatforms: [], //上线平台
        sceneType: "", //场景类型
        taskNumber: "", //任务单编号
        scheduleTaskId: "", //调度任务ID
        dispatchEngineer: "", //工程师
        corpId: "", //客户名称
        corpAttrId: "", //客户属性
        finishTime: [], //完成时间
        deliveryWays: [], //送货方式
        createTime: [nowTime - 2592000000], //创建日期
        createTimeStart: nowTime - 2592000000, //创建开始日期 默认一个月
        createTimeEnd: "",
        finishTimeStart: "", //期待解决时间开始
        finishTimeEnd: "", //期待解决时间结束
        regionalIdList: [],
        workTypeTagList: [],
        companyNos: [],
        deadLineTimeStart: "",
        deadLineTimeEnd: "",
        areaIdList: [],
        deadLineTime: [],
        inspectionSettleStatus: [],
        pageIndex: 1,
        pageSize: 20,
        warnType: "",
        warnStatus: "",
        hasWarnReason: "",
        secondOverdueReason: "",
        firstOverdueReason: "",
        labelTypeList: [],
      },
      warnTypeList: [],
      labelTypeList: [],
      hasWarnReasonList: [
        {
          id: 1,
          label: "已维护",
        },
        {
          id: 2,
          label: "未维护",
        },
      ],
      warnStatusList: [],
      WorkTypeTagList: [],
      isDeliverInstallList: [
        {
          codeName: "送装",
          codeValue: 1,
        },
        {
          codeName: "寄装",
          codeValue: 2,
        },
      ],
      onlinePlatList: [
        {
          codeName: "易流云",
          codeValue: 1,
        },
        {
          codeName: "G7S",
          codeValue: 2,
        },
      ],
      total: 0,
      columnData: [],
      taskSourceList: [],
      tableData: [], // 表格数据
      workStatusList: [], //工单状态下拉框
      taskTypeList: [], //工单类型
      sceneTypeList: [], //场景类型
      corpAtrrList: [], //客户属性
      taskSecondClassList: [], //任务单类型
      corpList: [], //客户名称
      selectColumns: [], //选中的数据
      exportType: "",
      // exportApi: exportG7workOrderList,
      areaList: [], //大区
      cityList: [], //片区
      serviceCompanyList: [],
      inspectionSettleStatusList: [],
      overdueReasonVisible: false,
      firstTypeList: [],
      dialogLoading: false,
      overdueReasonForm: {
        aaa: "",
        bbb: "",
      },
      firstReasonList: [],
      secondReasonList: [],
    };
  },
  components: { exportDialog, inputPlus, OverdueReasonDialog },
  mixins: [listPage, base, listPageReszie, exportData],
  watch: {
    // 完成时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        this.searchForm.finishTimeStart = val[0] || "";
        this.searchForm.finishTimeEnd = val[1] || "";
      },
    },
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        this.searchForm.createTimeStart = val[0] || "";
        this.searchForm.createTimeEnd = val[1] || "";
      },
    },
    // 关单核算时间
    "searchForm.deadLineTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.deadLineTime = this.searchForm.deadLineTime.reverse();
        }
        this.searchForm.deadLineTimeStart = val[0] || "";
        this.searchForm.deadLineTimeEnd = val[1] || "";
      },
    },
    "searchForm.provinceCode": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getAreaList("-256", this.searchForm.provinceCode);
        }
      },
    },
    "searchForm.cityCode": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getAreaList("-1", this.searchForm.cityCode);
        }
      },
    },
  },
  computed: {
    exportApi() {
      let value;
      if (this.exportType == 1) {
        value = exportWorkItemDetailList;
      } else {
        value = exportG7workOrderList;
      }
      return value;
    },
  },
  created() {
    this.initData();
    this.queryList();
    this.getAreaList("-65536", "");
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //获取省市区下拉框数据
    async getAreaList(type, code) {
      console.log(type, code, "~~~");
      try {
        let res = await getProviceList({ type, code });
        switch (type) {
          case "-65536":
            this.provinceList = res.data;
            break;
          case "-256":
            this.cityNameList = res.data;
            break;
          case "-1":
            this.districtList = res.data;
            break;
          default:
            break;
        }
      } catch (error) {
        printError(error);
      }
    },
    //点击导出
    handleExportDialog(exportType) {
      this.exportType = exportType;
      this.exportVisible = true;
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //加载客户名称
    async loadCorpData(val) {
      try {
        let res = await getWorkCorpList({
          pageNum: 1,
          pageSize: 200,
          corpName: val,
        });
        //获取客户名称
        this.corpList = this.getFreezeResponse(res, {
          path: "data.array",
        });
      } catch (error) {
        printError(error);
      }
    },
    async getSelectList(key, type) {
      try {
        let res = await findDownList([key]);
        if (type == 1) {
          this.firstReasonList = res.data[key];
        } else {
          this.secondReasonList = res.data[key];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    changeReason(val, node) {
      this.getSelectList(node.codeValue, 2);
    },
    handleReason() {
      if (!this.selectColumns.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.overdueReasonVisible = true;
    },
    closeDialog() {
      this.$refs.elTable.clearSelection();
      this.overdueReasonVisible = false;
    },
    handleSubmit() {},
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList([
            "itemStatus",
            "taskSecondClass",
            "sceneType",
            "taskSecondClass",
            "taskSource",
            "organization",
            "WorkTypeTag",
            "inspectionSettleStatus",
            "warnType",
            "warnStatus",
            "overdueReason",
            "workTags",
          ]),
          findCorpAttr({ id: null }),
          getListAreaAll({ areaType: 1 }),
          getListAreaAll({ areaType: 2 }),
        ];
        let [taskTypeRes, corpAttrRes, areaRes, cityRes] = await Promise.all(
          promiseList
        );
        //工单状态
        this.workStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.itemStatus",
        });
        //质检结算状态
        this.inspectionSettleStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.inspectionSettleStatus",
        });
        this.firstReasonList = this.getFreezeResponse(taskTypeRes, {
          path: "data.overdueReason",
        });
        this.WorkTypeTagList = this.getFreezeResponse(taskTypeRes, {
          path: "data.WorkTypeTag",
        });
        this.labelTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.workTags",
        });
        this.warnTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.warnType",
        });
        this.warnStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.warnStatus",
        });
        //签单组织
        this.serviceCompanyList = this.getFreezeResponse(taskTypeRes, {
          path: "data.organization",
        });
        //shang
        this.serviceCompanyList = this.getFreezeResponse(taskTypeRes, {
          path: "data.organization",
        });
        //工单类型
        this.taskTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSecondClass",
        });
        this.taskSourceList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSource",
        });
        //场景类型下拉框
        this.sceneTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.sceneType",
        });
        //获取客户属性
        this.corpAtrrList = this.getFreezeResponse(corpAttrRes, {
          path: "data",
        });
        //获取大区
        this.areaList = this.getFreezeResponse(areaRes, {
          path: "data",
        });
        //获取片区
        this.cityList = this.getFreezeResponse(cityRes, {
          path: "data",
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //查询表头
    async queryList() {
      try {
        this.loading = true;
        let res = await getColumn({
          tableId: this.tableId,
        });
        let columnData = res.data.configColumnList.filter(
          (item) => item.display
        );
        this.columnData = columnData;
        this.queryData();
      } catch (error) {
        printError(error);
      }
    },
    //查询全部任务单
    async queryData() {
      try {
        let res = await getG7workOrderList(this.searchForm);
        let tableData = res.data.array;
        tableData.map((item) => {
          item.tagValue = item.tagValue ? item.tagValue.split(",") : [];
          // item.equipList = item.equipList.map(item => item.equipName).join(",");
          return item;
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords",
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //多选
    handleSelect(columns) {
      this.selectColumns = columns;
    },
    // 日志
    toRecord() {
      this.routerPush({
        name: "taskOrderActiveRecord",
        params: {
          refresh: true,
        },
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1,
        },
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/ActionItemDetail",
          params: {
            refresh: true,
            itemId: row.workOrderId,
          },
        });
      }
    },

    //去任务单详情
    handleGoToDetail(row) {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true,
          taskId: row.taskId,
        },
      });
    },
    handleClose(name) {
      this[name] = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.table-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .label-box {
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #46bfea;
    color: #46bfea;
    margin: 5px;
  }
}
.status {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  background: #2791f7;
  border-radius: 20px;
  border: 1px solid #2791f7;
  color: #fff;
}
.finish {
  background: #e19b39;
  border: 1px solid #e19b39;
}
.ongoing {
  background: #69c43b;
  border: 1px solid #69c43b;
}
</style>
